@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  background: #444;
}

.slider,
.statement,
.pagecontent {
  height: 100vh;
  width: 100%;
}

.slider {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  filter: saturate(100%);
}

.statement {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: saturate(50%);
}

.pagecontent {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: saturate(100%);
}

.slider--content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.statement--content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  white-space: pre-line;
  vertical-align: bottom;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.pagecontent--content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  white-space: pre-line;
  vertical-align: bottom;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
}

.pagecontent--title {
  font-size: 5rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
}

.pagecontent--text {
  font-size: 1.5rem;
  color: #fff;
  margin: 1rem 10rem;
  font-weight: 300;
}

a {
  color: #fff;
}

.statement--artist {
  font-size: 2.5rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}

.statement--title {
  font-size: 1.5rem;
  color: #fff;
  text-transform: uppercase;
  margin: 1rem 0;
  font-weight: 500;
}

.statement--text {
  font-size: 1rem;
  color: #fff;
  margin: 1rem 0;
  font-weight: 300;

  line-height: 1.25rem;
}

.slider--feature {
  text-align: left;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2rem;
}

.statement--feature {
  text-align: center;
  padding: 0 10rem;
}

.feature--title {
  font-size: 1.5rem;
  color: #fff;
  text-transform: uppercase;
  width: 75%;
  font-weight: 500;
}

.feature--text {
  font-size: 1rem;
  color: #fff;
  margin: 0.5rem 0 0 0;
  width: 75%;
  font-weight: 300;
  line-height: 1.25rem;
}

.slider__btn-left,
.slider__btn-right {
  background: transparent;
  border: none;
  outline: none;
  font-size: 4rem;
  color: #eee;
  opacity: 0.5;
  padding: 0 2rem;
  cursor: pointer;
}

.slider__btn-hide,
.slider__btn-show {
  position: absolute;
  background: transparent;
  border: none;
  outline: none;
  font-size: 2rem;
  color: #eee;
  opacity: 0.5;
  cursor: pointer;
}

.slider__btn-hide {
  top: 2rem;
  right: 2rem;
}

.slider__btn-show {
  bottom: 2rem;
  right: 2rem;
}

.slider__btn-left:hover,
.slider__btn-right:hover,
.slider__btn-hide:hover,
.slider__btn-show:hover {
  opacity: 0.75;
}

.statement__btn {
  background: #fff;
  text-transform: uppercase;
  border: none;
  color: #444;
  border: none;
  outline: none;
  font-weight: 700;
  margin: 0 0.25rem;
  padding: 0.75rem 1.25rem;
  cursor: pointer;

}

.hide {
  display: none;
}

.logo {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.copyright {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  color: #fff;
  font-size: 0.8rem;
}